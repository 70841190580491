import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://boyo.publinet.rw/api/"
    : "http://localhost/talar-api/";
axios.defaults.baseURL = baseURL;
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    cartItems: localStorage.getItem("cartItems") || [],
    token: localStorage.getItem("token") || "",
    chatToken: localStorage.getItem("chatToken") || "",
    user: {},
    serverResponse: {},
    base_url:
      process.env.NODE_ENV === "production"
        ? "https://boyo.publinet.rw/api/"
        : "http://localhost/talar-api/",
    isLoading: false,
    activeBtn: null,
    drawerOpen: false,
    accountDrawer: false
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    cart_request(state) {
      state.status = "cartloading";
    },
    cart_success(state, cartItems) {
      state.status = "cartsuccess";
      state.cartItems = cartItems;
    },
    clear_cart(state) {
      state.status = "";
      state.cartItems = [];
    },
    chat_request(state) {
      state.status = "chat-requesting";
    },
    chat_success(state, chatToken) {
      state.status = "chat-response";
      state.chatToken = chatToken;
    }
  },

  actions: {
    authRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: dataParams.url,
          data: dataParams.formData,
          method: "POST"
        })
          .then(resp => {
            const token = resp.data.token;
            if (token) {
              localStorage.removeItem("token");
              localStorage.setItem("token", token);
              axios.defaults.headers.common["Authorization"] = token;
              commit("auth_success", token);
            }

            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post("ap/log-user/", user)
          .then(resp => {
            const token = resp.data.token;
            if (token) {
              localStorage.setItem("token", token);
              axios.defaults.headers.common["Authorization"] = token;
              commit("auth_success", token);
            }
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logAdmin({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post("duka-root/authenticate/", user)
          .then(resp => {
            const token = resp.data.token;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token);
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise(resolve => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },

    dukaPostRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        if (token) {
          dataParams.formData.append("token", token);
        }
        axios
          .post(dataParams.url, dataParams.formData)
          .then(resp => {
            if (resp.data.unauthorized) {
              this.dispatch("logout").then(() => {
                window.location.replace("/");
              });
            }
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },

    dukaGetRequest({ commit }, getURL) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        var fd = new FormData();
        if (token) {
          fd.append("token", token);
        }
        axios
          .post(getURL, fd)
          .then(resp => {
            if (resp.data.unauthorized) {
              this.dispatch("logout").then(() => {
                window.location.replace("/");
              });
            }
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    cartRequest({ commit }, dataParams) {
      return new Promise(resolve => {
        commit("cart_request");
        let available = 0;
        const itemsName =
          dataParams.isLoan === true ? "loanCartItems" : "cartItems";
        let cartItems = localStorage.getItem(itemsName);
        if (cartItems) {
          let temp = JSON.parse(cartItems);
          temp.forEach(arrItem => {
            if (arrItem.product_id === dataParams.product_id) {
              var objectsAreSame = true;
              for (var elt in arrItem.options) {
                if (arrItem.options[elt] !== dataParams.options[elt]) {
                  objectsAreSame = false;
                  break;
                }
              }
              if (objectsAreSame) {
                arrItem.qty = Number(arrItem.qty) + Number(dataParams.qty);
                available++;
              }
            }
          });
          if (available === 0) {
            temp.push(dataParams);
          }
          localStorage.setItem(itemsName, JSON.stringify(temp));
          if (dataParams.isLoan === true) commit("loan_cart_success", temp);
          else commit("cart_success", temp);
        } else {
          let temp = [];
          temp.push(dataParams);
          localStorage.setItem(itemsName, JSON.stringify(temp));
          if (dataParams.isLoan === true) commit("loan_cart_success", temp);
          else commit("cart_success", temp);
        }
        resolve();
      });
    },

    cartRemove({ commit }, dataParams) {
      return new Promise(resolve => {
        commit("cart_request");
        const itemsName =
          dataParams.isLoan === true ? "loanCartItems" : "cartItems";
        let cartItems = localStorage.getItem(itemsName);

        if (cartItems) {
          let temp = JSON.parse(cartItems);
          temp.forEach(arrItem => {
            if (arrItem.product_id === dataParams.product_id) {
              let idx = temp.indexOf(arrItem);
              temp.splice(idx, 1);
            }
          });
          localStorage.setItem(itemsName, JSON.stringify(temp));
          if (dataParams.isLoan === true) commit("loan_cart_success", temp);
          else commit("cart_success", temp);
        }
        resolve();
      });
    },

    clearCart({ commit }) {
      return new Promise(resolve => {
        commit("clear_cart");
        localStorage.removeItem("cartItems");
        resolve();
      });
    },
    cartQtyRequest({ commit }, dataParams) {
      return new Promise(resolve => {
        commit("cart_request");
        const itemsName =
          dataParams.isLoan === true ? "loanCartItems" : "cartItems";
        let cartItems = localStorage.getItem(itemsName);

        let temp = JSON.parse(cartItems);
        temp.forEach(arrItem => {
          if (arrItem.product_id === dataParams.product_id) {
            arrItem.qty = Number(dataParams.qty);
          }
        });
        localStorage.setItem(itemsName, JSON.stringify(temp));
        if (dataParams.isLoan === true) commit("loan_cart_success", temp);
        else commit("cart_success", temp);
        resolve();
      });
    },
    chatRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        commit("chat_request");
        const token = localStorage.getItem("chatToken");
        if (token) {
          dataParams.formData.append("token", token);
        } else {
          if (navigator) {
            let client_plaform = navigator.platform;
            dataParams.formData.append("client_platform", client_plaform);
          }
        }
        axios({
          url: dataParams.url,
          data: dataParams.formData,
          method: "POST"
        })
          .then(resp => {
            const token = resp.data.chat_token;
            if (token) {
              localStorage.setItem("chatToken", token);
              commit("chat_success", token);
            }
            resolve(resp);
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("chatToken");
            reject(err);
          });
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    chatInitialized: state => !!state.chatToken,
    authStatus: state => state.status,
    cartItems: state => state.cartItems,
    responseData: state => state.serverResponse
  }
});
