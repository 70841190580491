<template>
  <button
    :class="classes"
    :type="buttonType"
    @click="
      $store.state.activeBtn = activator;
      $emit('btn-role');
    "
    :disabled="$store.state.isLoading || disabler"
  >
    <template v-if="$store.state.activeBtn === activator">
      <svg
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        :stroke="color"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="4">
            <circle stroke-opacity="0.25" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="0.8s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
      {{ loadText }}
    </template>
    <template v-else>{{ caption }}</template>
  </button>
</template>
<script>
export default {
  name: "DukaButtons",
  data: () => ({ axiosRequestings: false }),
  props: {
    caption: {
      type: String,
      required: true
    },
    loadText: {
      type: String,
      required: true
    },
    classes: {
      type: String,
      required: true
    },
    buttonType: {
      type: String,
      default: "button"
    },
    height: {
      type: Number,
      default: 14
    },
    width: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: "#fff"
    },
    activator: {
      type: String,
      required: true
    },
    disabler: {
      type: Boolean,
      default: false
    }
  }
};
</script>
