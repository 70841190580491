<template>
  <div
    class="modal fade show quick-apply"
    style="z-index:999999999; display:block; background:rgba(0,0,0,0.7)"
  >
    <div class="modal-dialog quick-dialog" :class="[modalSize]">
      <div class="modal-content quick-document">
        <div
          class="modal-header quick-header"
          :style="{ backgroundColor: headerColor + '!important' }"
        >
          <h6 class="modal-title quick-title">
            <slot name="head"></slot>
          </h6>
          <button class="close quick-close" @click="$emit('close')">
            <span aria-hidden="true" class="text-white">
              <i class="dripicons-cross text-white"></i>
            </span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="foot"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DukaModalForm",
  props: {
    modalSize: String,
    headerColor: {
      type: String,
      default: "#041e42"
    }
  }
};
</script>
<style scoped>
.quick-apply {
  display: block;
  padding-top: 0px !important;
  padding-right: 0px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: right, box-shadow;
  -moz-transition-property: right, box-shadow;
  -o-transition-property: right, box-shadow;
  transition-property: right, box-shadow;
}
.quick-dialog {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0;
  float: none;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: right, box-shadow;
  -moz-transition-property: right, box-shadow;
  -o-transition-property: right, box-shadow;
  transition-property: right, box-shadow;
}
.quick-document {
  border-radius: 0;
  min-height: 100vh;
  padding: 0;
  border-color: transparent;
}
.quick-header {
  border: none;
  border-radius: 0;
}
.quick-title {
  color: #fff !important;
  font-weight: 400;
}
@media (min-width: 576px) {
  .quick-dialog {
    max-width: 650px;
  }
}
.quick-close {
  color: #fff;
  padding-bottom: 10px !important;
  font-size: 20px;
}
.loan-row {
  -webkit-box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  box-shadow: 0 0 50px 0 rgba(22, 104, 183, 0.15);
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.wizard-content .wizard.wizard-circle > .steps .step {
  width: 47px;
  line-height: 5px;
}
</style>
