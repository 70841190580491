<template>
  <div>
    <transition enter-active-class leave-active-class="animated rollOut">
      <div
        class="modal fade show"
        style="z-index:999999999; display:block; background:rgba(0,0,0,0.7)"
      >
        <div class="modal-dialog modal-dialog-centered" :class="[modalSize]">
          <div
            class="modal-content rounded-0"
            style="border-color: transparent;"
          >
            <div class="modal-header duka-modal-header">
              <h6 class="modal-title text-white" style="width:100%">
                <slot name="head"></slot>
              </h6>
              <button class="close" @click="$emit('close')">
                <span aria-hidden="true" class="text-white">×</span>
              </button>
            </div>
            <div class="modal-body">
              <slot name="body"></slot>
            </div>
            <div class="modal-footer">
              <slot name="foot"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "DukaModal",
  props: {
    modalSize: String
  }
};
</script>
<style scoped>
.modal-xg {
  max-width: 1000px !important;
}
.duka-modal-header {
  background: #041e42 !important;
  border-radius: 0;
  color: #fff;
}
</style>
