import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "./store";
const options = {
  latencyThreshold: 200,
  router: true,
  http: false
};
Vue.use(NProgress, options);
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /*
    | Routers for Index
    */
    {
      path: "*",
      name: "error404",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/Error404.vue"
        )
    },
    {
      path: "/send-money",
      name: "home",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/Landing.vue"
        ),
      meta: {
        title: "Welcome to Boyoro Money Transfer"
      }
    },
    {
      path: "/register",
      name: "signup",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/SignUp.vue"
        ),
      meta: {
        title: "Sign up to Taler Money Transfer"
      }
    },
    {
      path: "/verify-email/:code",
      name: "linkverification",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/LinkVerification.vue"
        ),
      meta: {
        title: "Verify your email"
      }
    },
    {
      path: "/company-verify-email/:code",
      name: "companyverification",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/CompanyVerification.vue"
        ),
      meta: {
        title: "Email verification"
      }
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/Login.vue"
        ),
      meta: {
        title: "Log into your account"
      },
      props: true
    },
    {
      path: "/reset-account-password",
      name: "resetpassword",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/ResetPassword.vue"
        ),
      meta: {
        title: "Reset Password"
      }
    },
    {
      path: "/change-password/:code",
      name: "changepassword",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/PasswordChange.vue"
        ),
      meta: {
        title: "Change Password"
      }
    },
    {
      path: "/offer-checkout/:code",
      name: "offercheckout",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/OfferCheckout.vue"
        ),
      meta: {
        title: "Offer Checkout"
      }
    },
    {
      path: "/talar-offer-checkout/:code",
      name: "talaroffercheckout",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/TalarOfferCheckout.vue"
        ),
      meta: {
        title: "Boyoro Offer Checkout"
      }
    },
    {
      path: "/account",
      name: "companyaccount",
      component: () =>
        import(
          /* webpackChunkName: "customerAccount" */ "./account/components/CustomerAccount.vue"
        ),
      meta: {
        title: "User Account",
        requiresUserAuth: true
      }
    },
    {
      path: "/account/offers",
      name: "companyoffers",
      component: () =>
        import(
          /* webpackChunkName: "customerAccount" */ "./account/components/Offers.vue"
        ),
      meta: {
        title: "Offers",
        requiresUserAuth: true
      }
    },
    {
      path: "/account/offers-payments-tracking",
      name: "offerspayment",
      component: () =>
        import(
          /* webpackChunkName: "customerAccount" */ "./account/components/OffersPayments.vue"
        ),
      meta: {
        title: "Offers Tracking",
        requiresUserAuth: true
      }
    },
    /*
    | Routers for Admin
    */
    {
      path: "/admin/secret",
      name: "adminlogin",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/AdminLogin.vue"
        ),
      meta: {
        title: "Admin Login"
      }
    },
    {
      path: "/admin/secret/dashboards",
      name: "admindashboards",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Landing.vue"
        ),
      meta: {
        title: "dashboards",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/change-password",
      name: "adminchangepassword",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/ChangePassword.vue"
        ),
      meta: {
        title: "Change Password",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/shopping-departments",
      name: "shoppingdeparts",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/ProductCategory.vue"
        ),
      meta: {
        title: "Categories",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/shopping-departments/product-variations/:slug",
      name: "Productsvariations",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/ProductsVariations.vue"
        ),
      meta: {
        title: "Variations",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/product-creation/categories",
      name: "categoriespickup",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/beforeProductAdd.vue"
        ),
      meta: {
        title: "Category Selection",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/create-product-listing/:slug",
      name: "createproduct",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/AddProduct.vue"
        ),
      meta: {
        title: "Product creation",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/product-editing/:slug",
      name: "editproduct",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/EditProduct.vue"
        ),
      meta: {
        title: "Product editing",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/products-listing",
      name: "adminproducts",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/shop/Products.vue"
        ),
      meta: {
        title: "Products Listing",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/users",
      name: "companies",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Companies.vue"
        ),
      meta: {
        title: "Registered companies",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/companies/balances",
      name: "companiesbalance",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Balance.vue"
        ),
      meta: {
        title: "Companies Accounts",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/customers",
      name: "customers",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Customers.vue"
        ),
      meta: {
        title: "Customers listing",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/messages",
      name: "visitorsmessages",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/EmailApp.vue"
        ),
      meta: {
        title: "Chat messages",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/orders",
      name: "adminorders",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Orders.vue"
        ),
      meta: {
        title: "Placed Orders",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/money-transfers",
      name: "admintransfers",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Transfers.vue"
        ),
      meta: {
        title: "Transfers",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/offers",
      name: "adminoffers",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Offers.vue"
        ),
      meta: {
        title: "Offers",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/offers-payments-tracking",
      name: "adminofferspayment",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/OffersPayments.vue"
        ),
      meta: {
        title: "Offers Tracking",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/companies-payments-history",
      name: "adminofferstracking",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/OffersTracking.vue"
        ),
      meta: {
        title: "Offers Payment History",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/widthdraw-requests",
      name: "withdrawrequests",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Requests.vue"
        ),
      meta: {
        title: "Withdraw Requests",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/products-widgets",
      name: "productswidget",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/ProductsWidget.vue"
        ),
      meta: {
        title: "Widgets",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/cms-banners-list",
      name: "cmsbannerslist",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/CmsBannersList.vue"
        ),
      meta: {
        title: "Banners List",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/cms-banners/add",
      name: "cmsbannersadd",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/CmsBanner.vue"
        ),
      meta: {
        title: "Add Banner",
        requiresAdminAuth: true
      }
    },
    {
      path: "/admin/secret/content-management",
      name: "sections",
      component: () =>
        import(
          /* webpackChunkName: "thaleradministrator" */ "./admin/components/Sections.vue"
        ),
      meta: {
        title: "Content Management",
        requiresAdminAuth: true
      }
    },
    /*E-coommerce routes */
    {
      path: "/",
      name: "shophome",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/ShopLanding.vue"
        ),
      meta: {
        title: "Store"
      }
    },
    {
      path: "/shop/product-details/:category_slug/:slug",
      name: "shopsingle",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/ProductSingle.vue"
        ),
      meta: {
        title: "Product details"
      }
    },
    {
      path: "/shop",
      name: "shop",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/Shop.vue"
        ),
      meta: {
        title: "Shop"
      }
    },
    {
      path: "/shop/:category_slug",
      name: "shopsinglecategory",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/Shop.vue"
        ),
      meta: {
        title: "Store"
      }
    },
    {
      path: "/shopping/cart",
      name: "shoppingcart",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/ShopingCart.vue"
        ),
      meta: {
        title: "Shopping Cart"
      }
    },
    {
      path: "/shopping/check-out-process",
      name: "checkoutprocess",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/CheckOutProcess.vue"
        ),
      meta: {
        title: "Checkout process",
        requiresUserAuth: true
      }
    },
    {
      path: "/checkout-complete",
      name: "ordercomplete",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/CheckoutComplete.vue"
        ),
      meta: {
        title: "Thank you for purshasing"
      },
      props: true
    },
    {
      path: "/order-searching",
      name: "ordersearching",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/OrderSearch.vue"
        ),
      meta: {
        title: "Order Searching"
      }
    },
    {
      path: "/about-us",
      name: "aboutus",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/AboutUs.vue"
        ),
      meta: {
        title: "About Us"
      }
    },
    {
      path: "/contact-us",
      name: "contactus",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/ContactUs.vue"
        ),
      meta: {
        title: "Contact us"
      }
    },
    {
      path: "/user",
      name: "customeraccount",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/User.vue"
        ),
      children: [
        {
          path: "",
          name: "useraccount",
          component: () =>
            import(
              /* webpackChunkName: "talarhome" */ "./index/components/CustomerOrders.vue"
            ),
          meta: {
            title: "Your Orders",
            requiresUserAuth: true
          }
        },
        {
          path: "/user/orders",
          name: "customerorders",
          component: () =>
            import(
              /* webpackChunkName: "talarhome" */ "./index/components/CustomerOrders.vue"
            ),
          meta: {
            title: "Your Orders",
            requiresUserAuth: true
          }
        },
        {
          path: "/user/transfers",
          name: "usertransfers",
          component: () =>
            import(
              /* webpackChunkName: "talarhome" */ "./index/components/Transfers.vue"
            ),
          meta: {
            title: "Transfers History",
            requiresUserAuth: true
          }
        },
        {
          path: "/user/profile",
          name: "userprofile",
          component: () =>
            import(
              /* webpackChunkName: "talarhome" */ "./index/components/Profile.vue"
            ),
          meta: {
            title: "User Profile",
            requiresUserAuth: true
          }
        },
        {
          path: "/user/addresses",
          name: "useraddresses",
          component: () =>
            import(
              /* webpackChunkName: "talarhome" */ "./index/components/Addresses.vue"
            ),
          meta: {
            title: "Your Addressess",
            requiresUserAuth: true
          }
        }
      ]
    },
    {
      path: "/user/money-transfer",
      name: "moneytransfer",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/sendMoney.vue"
        ),
      meta: {
        title: "Send Money",
        requiresUserAuth: true
      }
    },
    {
      path: "/order-tracking/:origin",
      name: "ordertracking",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/OrderTracking.vue"
        ),
      meta: {
        title: "Order Tracking"
      }
    },
    {
      path: "/home-search",
      name: "homesearching",
      component: () =>
        import(
          /* webpackChunkName: "talarhome" */ "./index/components/HomeSearch.vue"
        ),
      meta: {
        title: "Search Results"
      }
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.matched.some(record => record.meta.requiresUserAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/login");
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/admin");
  } else if (to.matched.some(record => record.meta.requiresCustomerAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/login");
  } else {
    next();
  }
});

const DEFAULT_TITLE = "Boyoro";
router.afterEach(to => {
  NProgress.done();
  Vue.nextTick(() => {
    document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
    if (store.state.drawerOpen) store.state.drawerOpen = false;
    if (store.state.accountDrawer) store.state.accountDrawer = false;
    if (document.body.classList.contains("offcanvas-open"))
      document.body.classList.remove("offcanvas-open");
  });
});

export default router;
