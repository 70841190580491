<template>
  <section>
    <bars-loader
      :color="color"
      :height="height"
      :width="width"
      v-if="spinnerType === 'Bars'"
    />
    <spinner-loader
      :color="color"
      :height="height"
      :width="width"
      v-if="spinnerType === 'Spinners'"
    />
    <dots-loader
      :color="color"
      :height="height"
      :width="width"
      v-if="spinnerType === 'Dots'"
    />
  </section>
</template>
<script>
import LoaderBars from "./LoaderBars.vue";
import LoaderSpinner from "./LoaderSpinner.vue";
import LoaderDots from "./LoaderDots.vue";
export default {
  name: "Loader",
  components: {
    "bars-loader": LoaderBars,
    "spinner-loader": LoaderSpinner,
    "dots-loader": LoaderDots
  },
  props: {
    color: {
      type: String,
      default: "#000"
    },
    height: {
      type: Number,
      default: 40
    },
    width: {
      type: Number,
      default: 40
    },
    spinnerType: {
      type: String,
      default: "Bars",
      required: true
    }
  }
};
</script>
