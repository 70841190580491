import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
export const dukaCore = {
  data() {
    return {
      dukaURL:
        process.env.NODE_ENV === "production"
          ? "https://boyo.publinet.rw/api/"
          : "http://localhost/talar-api/",
      formTitle: null,
      formSubtitle: null,
      emptyResult: false,
      records: [],
      recordsLength: 0,
      dbResponse: {},
      //pagination
      currentPage: 0,
      rowCountPage: 10,
      totalRows: 0,
      pageRange: 5,
      paginationURL: null,
      responseRef: null,
      currentUser: {},
      userActivated: false,
      currentAdmin: {},
      default_avatar: [
        "",
        "#9c27b0",
        "#03a9f4",
        "#F24F23",
        "#C2185B",
        "#004E42",
        "#4D4D4D",
        "#6D24FE",
        "#7D59C0"
      ],
      avatarUrl: null,
      imgDataUrl: null,
      userAddress: {},
      refCount: 0,
      isLoading: false,
      alertDefaults: {
        title: null,
        content: null,
        actionType: null,
        actionButton: null,
        classes: null,
        modalOpen: false
      },
      activeCroppedImage: null,
      defaultCropImage: null,
      choosenRow: {},
      dukaGallery: [],
      defaultCropGallery: [],
      trashedImageUrl: null,
      motorYears: [],
      showSingleUpload: false,
      showGalleryUpload: false,
      modalOpen: false,

      // upload
      pickedFile: null,
      uploadingProgress: false,
      progressWidth: 0,
      progressCount: null,
      rcb_document: null,
      uploaded: false,
      steps: [],
      content: null,
      // Cart here
      cartData: {
        items: 0
      },
      cartProducts: [],
      qtyError: false,
      filterKey: null,
      visitorCountry: {},
      countryName: "Rwanda",
      countryFlag: "rw.svg"
    };
  },
  computed: {
    cartItems: function() {
      return this.$store.getters.cartItems;
    },
    isDukaLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    filteredRecords: function() {
      let filterKey = this.filterKey && this.filterKey.toLowerCase();
      let records = this.records;
      if (filterKey) {
        records = records.filter(row => {
          return Object.keys(row).some(key => {
            return (
              String(row[key])
                .toLowerCase()
                .indexOf(filterKey) > -1
            );
          });
        });
      }
      return records;
    }
  },
  mounted() {
    document.querySelectorAll("span.to_be_hidden input").forEach(elt => {
      if (!elt.value.match(/^\s*$/)) elt.classList.add("filled-out");
      elt.addEventListener("focusout", e => {
        if (e.target.value == "") elt.classList.remove("filled-out");
        else elt.classList.add("filled-out");
      });
    });
  },
  created() {
    this.defaultCropImage = null;
    this.trashedImageUrl = this.dukaURL + "ap/trashed-images";
    axios.interceptors.request.use(
      config => {
        NProgress.start();
        return config;
      },
      error => {
        NProgress.done();
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      response => {
        NProgress.done();
        return response;
      },
      error => {
        NProgress.done();
        return Promise.reject(error);
      }
    );
  },
  methods: {
    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
        this.isLoading = true;
      } else if (this.refCount > 0) {
        this.refCount--;
        this.isLoading = this.refCount > 0;
      }
    },
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        if (obj[key] !== null) {
          if (typeof obj[key] === "object")
            formData.append(key, JSON.stringify(obj[key]));
          else formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    incrementIndex(nbr) {
      return nbr + 1;
    },
    setUserAvatar() {
      let color = Math.floor(Math.random() * 8 + 1);
      return this.default_avatar[color];
    },
    signout() {
      this.$store.dispatch("logout").then(() => {
        window.location.replace("/");
      });
    },
    generateAttributesForm(attributes) {
      let attributeForm = {};
      attributes.forEach(arrItem => {
        if (arrItem.label === "") {
          arrItem.label = "Undefined field";
        }
        switch (arrItem.field_type) {
          case "checkbox":
            attributeForm[arrItem.label] = [];
            break;
          default:
            attributeForm[arrItem.label] = null;
            break;
        }
      });
      return attributeForm;
    },
    explodeString(trigger, str) {
      return str.split(trigger);
    },
    capitalizeFirst: str => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    rideUnderscores(str) {
      return str.replace(/_/g, " ");
    },
    toggleParentClass(elementId, className) {
      let Element = document.getElementById(elementId);
      Element.classList.toggle(className);
    },
    closeAlert() {
      this.alertDefaults.modalOpen = false;
    },
    toggleModalOpen() {
      const elt = document.documentElement;
      if (elt.classList.contains("modal-open"))
        elt.classList.remove("modal-open");
      else elt.classList.add("modal-open");

      const el = document.body;
      if (el.classList.contains("modal-open"))
        el.classList.remove("modal-open");
      else el.classList.add("modal-open");
    },
    toggleModal() {
      this.toggleModalOpen();
      this.modalOpen = !this.modalOpen;
    },
    clearObject(obj) {
      for (let key in obj) {
        if (obj[key] === true) obj[key] = false;
        else if (obj[key] === false) continue;
        else obj[key] = null;
      }
      return obj;
    },
    falseObject(obj, On) {
      for (let key in obj) {
        if (key === On) obj[key] = true;
        else obj[key] = false;
      }
    },
    dukaUploadSuccess(jsonData, field) {
      this.activeCroppedImage = jsonData.filename;
      return field;
    },
    dukaCropSuccess(imgDataUrl, field) {
      this.defaultCropImage = imgDataUrl;
      return field;
    },

    dukaGalleryImage(imgDataUrl, field) {
      this.dukaGallery.push({ image: imgDataUrl });
      return field;
    },
    dukaGalleryUpload(jsonData, field) {
      this.defaultCropGallery.push(jsonData.filename);
      return field;
    },
    RemoveGalleryImage(key) {
      this.dukaGallery.splice(key, 1);
      this.defaultCropGallery.splice(key, 1);
    },
    selectRecord(record) {
      this.choosenRow = record;
    },
    toggleSingleUpload() {
      this.showSingleUpload = !this.showSingleUpload;
      this.$refs.singleUpload.reset();
      this.$refs.singleUpload.setStep(1);
    },
    toggleGalleryUpload() {
      this.showGalleryUpload = !this.showGalleryUpload;
      this.$refs.galleryUpload.reset();
      this.$refs.galleryUpload.setStep(1);
    },
    isEqual(arrLen, key) {
      return key === arrLen - 1;
    },
    isEmpty(obj) {
      const isNULL = Object.values(obj).every(x => x === null);
      return Object.keys(obj).length === 0 || isNULL === true;
    },
    needItems: field_type => {
      return field_type === "checkbox" || field_type === "select";
    },
    salePrice(orginal_price, sale_percentage) {
      return this.dukaFloatFormatter(
        orginal_price - (sale_percentage * orginal_price) / 100
      );
    },
    computePercentage(regular_price, price) {
      return (100 * (regular_price - price)) / regular_price;
    },
    salePriceT(orginal_price, sale_percentage) {
      return this.dukaFloatFormatter(
        orginal_price + (sale_percentage * orginal_price) / 100
      );
    },
    addComma(num) {
      let regexp = /\B(?=(\d{3})+(?!\d))/g;
      return Number(num) ? num.toString().replace(regexp, ",") : null;
    },
    formatFloatNumber(original, point_alias) {
      return Math.round(original * point_alias) / point_alias;
    },
    dukaFloatFormatter(number) {
      let num = number.toString();
      let numParts = num.split(".");
      let lastPart = numParts[1] ? "." + numParts[1].slice(0, 2) : "";
      return this.addComma(parseInt(Number(numParts[0]))) + lastPart;
    },
    incrementObjectKey(obj, key, max = null) {
      if (max) {
        if (Number(obj[key]) <= max) {
          obj[key] = Number(obj[key]) + 1;
        }
      } else {
        obj[key] = Number(obj[key]) + 1;
      }
    },
    decrementObjectKey(obj, key, min = 1) {
      if (Number(obj[key]) > min) {
        obj[key] = Number(obj[key]) - 1;
      }
    },
    removeComma(str) {
      return Number(str.replace(/,/g, ""));
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    chatDate(str) {
      let options = {
        weekday: "short",
        month: "short",
        day: "numeric"
      };
      let today = new Date(str);
      return today.toLocaleDateString("en-US", options);
    },
    dukaHotelDate(str) {
      let options = {
        year: "numeric",
        month: "short",
        day: "numeric"
      };
      let today = new Date(str);
      return today.toLocaleDateString("en-US", options);
    },
    formatOrderDate(order_date) {
      let str = this.explodeString(" ", order_date);
      return this.dukaHotelDate(str[0]);
    },
    rcbUpload() {
      let uploadFile = document.getElementById("rcbfile");
      let FileUploadPath = uploadFile.value;
      let Extension = FileUploadPath.substring(
        FileUploadPath.lastIndexOf(".") + 1
      ).toLowerCase();
      if (
        Extension == "gif" ||
        Extension == "png" ||
        Extension == "pdf" ||
        Extension == "jpeg" ||
        Extension == "jpg"
      ) {
        let fd = new FormData();
        fd.append("duka_upload", uploadFile.files[0], uploadFile.files[0].name);

        this.pickedFile = uploadFile.files[0].name;
        this.uploadingProgress = true;
        this.axios
          .post(this.trashedImageUrl, fd, {
            onUploadProgress: uploadEvent => {
              let percentage = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100
              );
              this.progressWidth = percentage + "%";
              this.progressCount = percentage;
            }
          })
          .then(response => {
            this.uploaded = true;
            this.rcb_document = response.data.filename;
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    merge_options(obj1, obj2) {
      let obj3 = {};
      for (let attrname in obj1) {
        obj3[attrname] = obj1[attrname];
      }
      for (let attrname1 in obj2) {
        obj3[attrname1] = obj2[attrname1];
      }
      return obj3;
    },
    dukaDisplayingSteps(records, n) {
      let len = records.length;
      this.steps.push({ s: 0, e: n - 1 });
      if (len >= n) {
        for (let i = 2; i <= len; i += n) {
          if (i + n >= len) this.steps.push({ s: i + 1, e: len });
          else this.steps.push({ s: i + 1, e: i + n });
        }
      }
    },
    cartesianProduct(arr) {
      return arr.reduce(
        (a, b) => {
          return a
            .map(x => {
              return b.map(y => {
                return x.concat(y);
              });
            })
            .reduce((a, b) => {
              return a.concat(b);
            }, []);
        },
        [[]]
      );
    },
    loadCurrentUser() {
      this.$store
        .dispatch("dukaGetRequest", "customer/get-current-user")
        .then(response => {
          this.currentUser = response.data.user_details;
          this.$store.state.user = response.data.user_details;
          this.$root.$emit("currentuser", response.data.user_details);
        });
    },
    loadCurrentAdmin() {
      this.$store
        .dispatch("dukaGetRequest", "duka-root/get-current-admin")
        .then(response => {
          this.currentAdmin = response.data.admin;
          this.$root.$emit("currentadmin", response.data.admin);
        });
    },
    loadPaginationRecords(DispatchpParams = null, singleResponse = false) {
      let actionDispatch =
        DispatchpParams !== null
          ? this.$store.dispatch("dukaPostRequest", DispatchpParams)
          : this.$store.dispatch("dukaGetRequest", this.paginationURL);
      actionDispatch.then(response => {
        this.$store.state.serverResponse = response.data;
        if (singleResponse === false) {
          this.dbResponse = response.data;
        }
        let responseData =
          this.responseRef === null
            ? response.data
            : response.data[this.responseRef];
        this.recordsLength = responseData.length;
        this.generatePagination(responseData);
      });
    },
    generatePagination(rows) {
      this.emptyResult = false;
      this.totalRows = rows.length;
      this.records = rows.slice(
        this.currentPage * this.rowCountPage,
        this.currentPage * this.rowCountPage + this.rowCountPage
      );
      // if the record is empty, go back a page
      if (this.records.length == 0 && this.currentPage > 0) {
        this.pageUpdate(this.currentPage - 1);
        //this.clearAll();
      }
    },
    pageUpdate(pageNumber) {
      this.currentPage = pageNumber;
      this.refresh();
    },
    refresh() {
      let responseData =
        this.responseRef === null
          ? this.dbResponse
          : this.dbResponse[this.responseRef];
      this.generatePagination(responseData);
      //this.loadPaginationRecords();
    },
    changePaginationRows(event) {
      this.rowCountPage = event.target.value;
      this.refresh();
    },
    filterProducts(inputAlias = null, divAlias = null) {
      let input, filter, div, a, i, txtValue;
      input =
        inputAlias !== null
          ? document.getElementById(inputAlias)
          : document.getElementById("myInput");
      filter = input.value.toUpperCase();
      div =
        divAlias !== null
          ? document.getElementById(divAlias)
          : document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    reloadPage() {
      window.location.reload();
    },
    moneyToWords(givenMoneny) {
      const arr = x => Array.from(x);
      const num = x => Number(x) || 0;
      //const str = x => String(x);
      const isEmpty = xs => xs.length === 0;
      const take = n => xs => xs.slice(0, n);
      const drop = n => xs => xs.slice(n);
      const reverse = xs => xs.slice(0).reverse();
      const comp = f => g => x => f(g(x));
      const not = x => !x;
      const chunk = n => xs =>
        isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

      // numToWords :: (Number a, String a) => a -> String
      let numToWords = n => {
        let a = [
          "",
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen"
        ];

        let b = [
          "",
          "",
          "twenty",
          "thirty",
          "forty",
          "fifty",
          "sixty",
          "seventy",
          "eighty",
          "ninety"
        ];

        let g = [
          "",
          "thousand",
          "million",
          "billion",
          "trillion",
          "quadrillion",
          "quintillion",
          "sextillion",
          "septillion",
          "octillion",
          "nonillion"
        ];

        // this part is really nasty still
        // it might edit this again later to show how Monoids could fix this up
        let makeGroup = ([ones, tens, huns]) => {
          return [
            num(huns) === 0 ? "" : a[huns] + " hundred ",
            num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
            a[tens + ones] || a[ones]
          ].join("");
        };

        let thousand = (group, i) =>
          group === "" ? group : `${group} ${g[i]}`;

        if (typeof n === "number") return numToWords(String(n));
        else if (n === "0") return "zero";
        else
          return comp(chunk(3))(reverse)(arr(n))
            .map(makeGroup)
            .map(thousand)
            .filter(comp(not)(isEmpty))
            .reverse()
            .join(" ");
      };
      return numToWords(givenMoneny);
    },
    quantitySum(isLoan = false) {
      const itemsName = isLoan === true ? "loanCartItems" : "cartItems";
      let data = localStorage.getItem(itemsName);
      return data
        ? JSON.parse(data).reduce((prev, cur) => prev + Number(cur.qty), 0)
        : 0;
    },
    afteraCart(isLoan = false) {
      if (isLoan === true)
        return {
          items: this.loanCartItems.reduce(
            (prev, cur) => prev + Number(cur.qty),
            0
          ),
          productsArr: this.parseItems(true)
        };
      else
        return {
          items: this.cartItems.reduce(
            (prev, cur) => prev + Number(cur.qty),
            0
          ),
          productsArr: this.parseItems()
        };
    },
    cartTotal(isLoan = false) {
      const itemsName = isLoan === true ? "loanCartItems" : "cartItems";
      let data = localStorage.getItem(itemsName);
      if (data)
        return JSON.parse(data).reduce(
          (prev, cur) =>
            prev + Number(cur.qty) * Number(cur.retail_price.replace(/,/g, ""))
        );
      else return 0;
    },
    getCartItems() {
      this.cartData.items = this.quantitySum();
    },
    getTotalItems() {
      this.cartData.items = this.cartItems.reduce(
        (prev, cur) => prev + Number(cur.qty),
        0
      );
    },
    getLoanCartItems() {
      this.loanCartData.items = this.quantitySum(true);
    },
    getLoanTotalItems() {
      this.loanCartData.items = this.loanCartItems.reduce(
        (prev, cur) => prev + Number(cur.qty),
        0
      );
    },
    parseItems(isLoan = false) {
      const itemsName = isLoan === true ? "loanCartItems" : "cartItems";
      let data = localStorage.getItem(itemsName);
      return data ? JSON.parse(data) : [];
    },
    treatAttributes(obj) {
      let result = [];
      for (let key in obj) {
        result.push(key + ":" + obj[key]);
      }
      return result;
    },
    getProdutSubTotal(product) {
      let price = this.salePrice(product.price, product.retail_price);
      return Number(product.qty) * Number(price.replace(/,/g, ""));
    },
    getCartDiscount(product) {
      let subtotal = this.getProdutSubTotal(product);
      return this.salePrice(subtotal, Number(product.retail_percentage));
    },
    updateScroll(eltID) {
      let container = document.getElementById(eltID);
      // element.scrollTop = element.scrollHeight;
      if (
        container.scrollHeight - container.clientHeight - container.scrollTop <=
        29
      ) {
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }
    },
    handleWolfPreview(imgDataUrl) {
      this.defaultCropImage = imgDataUrl;
    },
    keepWolfFile(filename) {
      this.activeCroppedImage = filename;
    },
    handleFileReset() {
      this.activeCroppedImage = null;
      this.defaultCropImage = null;
    },
    wolfGalleryImage(imgDataUrl) {
      this.dukaGallery.push({ image: imgDataUrl });
    },
    keepImageGallery(filename) {
      this.defaultCropGallery.push(filename);
    },
    loadContent(slug) {
      this.$store
        .dispatch("dukaGetRequest", "market/get-section/" + slug)
        .then(response => {
          this.content = response.data;
        });
    }
  }
};
